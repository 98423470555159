const LoadingScreen = () => {
  return (
    <div className="flex flex-col bg-white items-center justify-center w-full h-full fixed left-0 top-0">
      <div>
        <img width="65" alt="loading" src="/img/loading-3.svg" />
      </div>
    </div>
  )
}


export default LoadingScreen;
