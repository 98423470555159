import { createContext, useState, useEffect } from "react";
import { useCookie } from 'next-universal-cookie';
import { AuthContextDefaultValue, User } from "../../ts/interface";
import { getAccessToken, getUserData } from "../api/auth";
import LoadingScreen from "../../components/common/LoadingScreen";


const defaultValue: AuthContextDefaultValue = {
  accessToken: null,
  user: null,
  updateAccessToken: () => { },
  updateUser: () => { },
}

export const AuthContext = createContext(defaultValue);

const AuthProvider = ({ children }) => {
  const demoToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwOWU3MTk1YTZlNDE4MDAxNWY4M2QzMCIsImlhdCI6MTYyMjczMjQ4OCwiZXhwIjoxNjIyNzMzMzg4fQ.akt5P-aK8YjlK9svnVWQiCwOn8aajdZBOwYw6d0kTJA";
  const demoUser: User = {
    _id: "609e7195a6e4180015f83d30",
    firstName: "Joseph",
    lastName: "Denedo",
    category: "SS3",
    username: "demoUser01"
  }
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookie([]);

  const setRefreshToken = (refreshToken: string) => {
    setCookie("refreshToken", refreshToken, {
      path: '/',
      sameSite: "lax"
    });
  }

  const updateAccessToken = (token: string | null) => {
    setAccessToken(token);
  }

  const updateUser = (user: User | null) => {
    setUser(user);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (cookies) {
        const { refreshToken } = cookies;
        if (refreshToken) {
          try {
            const response = await getAccessToken(refreshToken);
            const { accessToken } = response;
            const user = await getUserData(accessToken);
            setRefreshToken(response.refreshToken);
            setAccessToken(accessToken);
            setUser(user);
            setLoading(false);
          } catch {
            setUser(null);
            setAccessToken(null);
            setLoading(false);
          }
        } else {
          setUser(null);
          setAccessToken(null);
          setLoading(false);
        }
      } else {
        setAccessToken(null);
        setUser(null);
        setLoading(false);
      }
      setLoading(false);
    }
    fetchData();
  }, [cookies]);

  const value = { user, accessToken, updateAccessToken, updateUser };

  if (loading) return <LoadingScreen />;

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}


export default AuthProvider;
