import axios, { AxiosRequestConfig } from "axios";
import { User, UserEditData, UserSignUpData } from "../../ts/interface";


export const signInWithUserNameAndPassword = async (username: string, password: string): Promise<{ user?: User, accessToken?: string, refreshToken?: string }> => {
  let userCredentials = {
    username: username,
    password: password,
  }

  let data: string = JSON.stringify(userCredentials);

  const config: AxiosRequestConfig = {
    data: data,
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API_URL}/auth/login`,
    headers: {
      'Content-Type': 'application/json'
    },
  }

  try {
    const response: any = await axios(config);
    const data = response.data;
    switch (response.status) {
      case 200:
        if (response.status === 200 && data.success) {
          let userData: User = {
            _id: data.user._id,
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            username: data.user.username,
            category: data.user.category
          }
          let accessToken: string = response.data.accessToken;
          let refreshToken: string = response.data.refreshToken;
          return { user: userData, accessToken: accessToken, refreshToken: refreshToken };
        }

        return { user: null, accessToken: null, refreshToken: null }
    }
  } catch (error) {
    throw error;
  }
}

export const signUpWithUserNameAndPassword = async (userData: UserSignUpData, successFeedback: any, failureFeedback: any) => {
  let data: string = JSON.stringify(userData);

  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API_URL}/auth/register`,
    data: data,
    headers: {
      'Content-Type': 'application/json'
    },
  };

  try {
    const response = await axios(config);
    if (response.status === 200 && response.data.success) {
      const { accessToken, refreshToken, user } = response.data;
      successFeedback();
      let userData: User = {
        _id: user._id,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        category: user.category
      }

      return {
        accessToken: accessToken,
        refreshToken: refreshToken,
        user: userData
      }
    }
    return null;
  } catch (error) {
    failureFeedback();
  }
}

export const getAccessToken = async (refreshToken: string) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API_URL}/auth/refresh_token`,
    headers: {
      'Authorization': `Bearer ${refreshToken}`,
    }
  };

  try {
    const response: any = await axios(config);
    const data = response.data;
    if (data.success) {
      const { accessToken, refreshToken } = data;
      return {
        accessToken,
        refreshToken
      }
    }
    return null;
  } catch (err) {
    return null;
  }
}

export const getUserData = async (accessToken: string): Promise<User> => {

  if (!accessToken) return null;

  const config: AxiosRequestConfig = {
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_URL}/auth/user`,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
    }
  };

  try {
    const response: any = await axios(config);
    const data = response.data;

    if (response.status === 200 && data.success) {
      const { _id, firstName, lastName, username, category } = data.data;
      return {
        _id,
        firstName,
        lastName,
        category,
        username
      }
    }

    return null;
  } catch { }
}

export const updateUserData = async (userData: UserEditData, userId: string, accessToken: string) => {
  if (!accessToken || !userData || !userId || !accessToken) {
    return false;
  }

  const data = JSON.stringify(userData);
  const config: AxiosRequestConfig = {
    method: 'put',
    url: `${process.env.NEXT_PUBLIC_API_URL}/user/${userId}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  };

  try {
    const response = await axios(config);
    console.log(response);
    const { success } = response.data;
    return success;
  } catch { }
}
